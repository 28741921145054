import colors from 'vuetify/lib/util/colors'

export function getColors(filter = 'base') {
  const allColors = Object.keys(colors)
    .map(c => colors[c])
    .filter(c => c[filter])
    .map(c => c[filter])

  return allColors
}

export function randomColor(filter = 'base') {
  const colors = getColors(filter)

  const rand = Math.round(Math.random() * (colors.length - 1))

  return colors[rand]
}

<template>
  <div>
    <v-navigation-drawer
      v-if="authObject"
      v-model="drawer"
      :mini-variant="miniDrawer"
      mini-variant-width="56"
      width="200"
      app
      clipped
      :mobile-break-point="mobileBreakPoint"
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.route"
          :to="item.to"
          color="primary"
        >
          <v-tooltip :disabled="!miniDrawer" right nudge-right="10px">
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>{{ $t('components.nav.lists') }}</v-subheader>

        <v-list-item
          v-for="list in activeLists"
          :key="list.id"
          :to="{ name: 'list', params: { listId: list.id } }"
          :color="minimal ? '' : list.color"
        >
          <v-tooltip :disabled="!miniDrawer" right nudge-right="10px">
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon>fas fa-list-ul</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ list.title }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ list.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="addList" color="primary">
          <v-tooltip :disabled="!miniDrawer" right nudge-right="10px">
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon>fas fa-plus</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t('components.nav.new-list') }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{
              $t('components.nav.new-list')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-subheader>{{ $t('components.nav.tags') }}</v-subheader>

        <div v-if="activeTags.length">
          <v-chip
            v-for="tag in activeTags"
            :key="tag.id"
            :to="{ name: 'tag', params: { tagId: tag.id } }"
            class="mr-1 px-2 black--text"
            :color="minimal ? '' : tag.color"
            x-small
            >{{ tag.id }}</v-chip
          >
        </div>
        <div v-else-if="!miniDrawer" class="text-caption grey--text">
          {{ $t('components.nav.create-tags-text') }}
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense clipped-left>
      <v-app-bar-nav-icon
        v-if="authObject"
        color="primary"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <router-link class="main-title" :to="{ name: 'home' }">
        <img
          class="logo-img"
          :class="{ 'invert-logo': darkMode }"
          height="25"
          src="@/assets/img/logo-full.png"
        />
      </router-link>

      <v-spacer />

      <template v-if="authObject">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on" small icon>
              <v-icon>fas fa-user-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in userMenuItems">
              <template v-if="item.divider">
                <div :key="index">
                  <v-divider />
                  <v-subheader v-if="item.subheader" v-text="item.subheader" />
                </div>
              </template>
              <v-list-item
                v-else
                :key="index"
                :href="item.href"
                :to="item.to"
                color="primary"
                @click="item.action() || null"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.subtitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-else-if="authObject === null">
        <v-btn class="primary" small :to="{ name: 'login' }">
          {{ $t('components.nav.login') }}
        </v-btn>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      drawer: null,
      mobileBreakPoint: 1264,
    }
  },

  computed: {
    ...mapState('app', ['miniDrawer', 'minimal', 'darkMode']),
    ...mapState('auth', ['authObject']),
    ...mapGetters('tags', ['activeTags']),
    ...mapGetters('lists', ['activeLists']),

    items() {
      return [
        {
          text: this.$t('components.nav.dashboard'),
          icon: 'fas fa-columns',
          to: { name: 'dashboard' },
        },
        {
          text: this.$t('components.nav.inbox'),
          icon: 'fas fa-inbox',
          to: { name: 'inbox' },
        },
      ]
    },

    userMenuItems() {
      return [
        {
          text: this.$t('components.nav.customize'),
          to: { name: 'customize' },
        },
        {
          text: this.$t('components.nav.account'),
          to: { name: 'account' },
        },
        {
          text: this.$t('components.nav.subscription'),
          to: { name: 'subscription' },
        },
        {
          divider: true,
          subheader: this.$t('components.nav.support'),
        },
        {
          text: this.$t('components.nav.tutorials'),
          action: () => this.SET_HELP_DRAWER(true),
        },
        {
          text: 'Feedback',
          subtitle: 'Feature requests & bug reports',
          to:
            '/sso?companyID=6252fcb2ad9724542d0b12bc&redirect=https%3A%2F%2Fbullet.canny.io%2Ffeedback',
        },
        {
          text: 'Contact',
          subtitle: 'Questions',
          href: 'mailto:hamish@bulletjournal.app',
        },
        {
          divider: true,
        },
        {
          text: this.$t('components.nav.log-out'),
          action: () => this.logout(),
        },
      ]
    },
  },

  methods: {
    ...mapMutations('app', ['TOGGLE_MINI_DRAWER', 'SET_HELP_DRAWER']),
    ...mapActions('auth', ['logout']),
    ...mapActions('lists', ['createList']),

    async addList() {
      const list = await this.createList()

      this.$router.push({ name: 'list', params: { listId: list.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-title {
  text-decoration: none;
  display: inline;
}
.logo-img {
  display: block;
}
.invert-logo {
  filter: invert(100%);
}
</style>

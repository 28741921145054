import supportedLocales from '@/config/supported-locales'

export const getSupportedLocales = () =>
  Object.keys(supportedLocales).map(code => {
    return {
      code,
      name: supportedLocales[code],
    }
  })

export const supportedLocalesInclude = locale =>
  Object.keys(supportedLocales).includes(locale)

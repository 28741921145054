import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import luxon from './plugins/luxon'
import './styles/main.scss'
import './plugins/truncate'
import i18n from './i18n'

Vue.use(luxon)

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

store.dispatch('app/setDarkMode', { vue })
store.dispatch('auth/listenAuthState')
store.dispatch('app/onResize')
store.dispatch('app/setStartingLocale')
store.dispatch('app/setDateAndScope', {
  date: vue.$DateTime.local(),
  scope: vue.$scopeMap.day,
})

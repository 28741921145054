import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import analytics from './helpers/analytics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' },
    // component: () => import(/* webpackChunkName: "about" */ './views/Home.vue'),
    // meta: {
    //   redirectUserToDashboard: true,
    // },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ './views/Login.vue'),
    meta: {
      redirectUserToDashboard: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "about" */ './views/Register.vue'),
    meta: {
      redirectUserToDashboard: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      import(/* webpackChunkName: "about" */ './views/ForgotPassword.vue'),
    meta: {
      redirectUserToDashboard: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/inbox',
    name: 'inbox',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Inbox.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/list/:listId',
    name: 'list',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tag/:tagId',
    name: 'tag',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Tag.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Account.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/customize',
    name: 'customize',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Customize.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Subscription.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/prompt/:promptId',
    name: 'prompt',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Prompt.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sso',
    name: 'sso',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/SSO.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ './views/Admin.vue'),
    meta: {
      requiresAdmin: true,
    },
  },

  // landing page
  {
    path: '/bullet-journal',
    name: 'landing-bujo',
    component: () =>
      import(/* webpackChunkName: "about" */ './views/landing-pages/Bujo.vue'),
  },
  {
    path: '/productivity',
    name: 'landing-productivity',
    component: () =>
      import(
        /* webpackChunkName: "about" */ './views/landing-pages/Productivity.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const redirectUserToDashboard = to.matched.some(
    record => record.meta.redirectUserToDashboard
  )
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  const canRoute = store.getters['auth/canRoute']

  // console.log('\ntoRoute: ', to.path, to)

  if (!canRoute) {
    // first entrypoint
    const watcher = store.watch(
      (state, getters) => getters['auth/canRoute'],
      canRoute => {
        if (canRoute) {
          watcher()
          routing()
        }
      }
    )
  } else {
    routing()
  }

  function routing() {
    let { authObject, claims } = store.state.auth

    if (requiresAdmin && !claims.admin) {
      next({ name: 'dashboard' })
    } else if (authObject && redirectUserToDashboard) {
      next({ name: 'dashboard' })
    } else if (!authObject && requiresAuth) {
      next(`/login?redirectUrl=${encodeURIComponent(to.fullPath)}`)
    } else {
      next()
    }
  }
})

router.afterEach(to => {
  analytics.page(to.name)
})

export default router

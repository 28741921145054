import { db, serverTimestamp, functions } from '../firebase'

const ADD_PROMPT = 'ADD_PROMPT'
const REPLACE_PROMPT = 'REPLACE_PROMPT'
const REMOVE_PROMPT = 'REMOVE_PROMPT'
const UPDATE_PROMPT = 'UPDATE_PROMPT'
const ADD_LISTENER = 'ADD_LISTENER'
const CLEAR_STATE = 'CLEAR_STATE'

const defaultState = () => {
  return {
    prompts: [],
    listeners: [],
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  mutations: {
    [ADD_PROMPT]: (state, payload) => state.prompts.push(payload),
    [REPLACE_PROMPT]: (state, payload) => {
      const index = state.prompts.findIndex(prompt => prompt.id === payload.id)
      state.prompts.splice(index, 1, payload)
    },
    [REMOVE_PROMPT]: (state, payload) => {
      const index = state.prompts.findIndex(prompt => prompt.id === payload.id)
      state.prompts.splice(index, 1)
    },
    [UPDATE_PROMPT]: (state, payload) => {
      const index = state.prompts.findIndex(prompt => prompt.id === payload.id)
      const updatedPrompt = Object.assign(
        {},
        state.prompts[index],
        payload.data
      )
      state.prompts.splice(index, 1, updatedPrompt)
    },
    [ADD_LISTENER]: (state, listener) => state.listeners.push(listener),
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
  },

  actions: {
    listenPrompts({ commit }, authObject) {
      const usersListener = db
        .collection(`users/${authObject.uid}/prompts`)
        .orderBy('dateCreated')
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const doc = change.doc
            const payload = {
              id: doc.id,
              ...doc.data(),
            }

            if (change.type === 'added') commit(ADD_PROMPT, payload)
            else if (change.type === 'modified') commit(REPLACE_PROMPT, payload)
            else if (change.type === 'removed') commit(REMOVE_PROMPT, payload)
          })
        })

      commit(ADD_LISTENER, usersListener)
    },

    createPrompt({ rootGetters }, text) {
      const uid = rootGetters['auth/uid']

      return db.collection(`users/${uid}/prompts`).add({
        dateCreated: serverTimestamp(),
        dateUpdated: serverTimestamp(),
        text,
        archived: false,
      })
    },

    updatePrompt({ rootGetters }, { promptId, data = {} }) {
      const uid = rootGetters['auth/uid']

      return db.doc(`users/${uid}/prompts/${promptId}`).update({
        dateUpdated: serverTimestamp(),
        ...data,
      })
    },

    destroyPrompt({ commit }, promptId) {
      const destroyPromptOnCall = functions.httpsCallable('destroyPromptOnCall')

      commit(UPDATE_PROMPT, { id: promptId, data: { deleting: true } })

      return destroyPromptOnCall({ promptId })
    },

    resetState({ state, commit }) {
      state.listeners.forEach(listener => listener())
      commit(CLEAR_STATE)
    },
  },
}

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import entries from './entries'
import app from './app'
import messaging from './messaging'
import lists from './lists'
import tags from './tags'
import repeaters from './repeaters'
import prompts from './prompts'
import stripe from './stripe'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    resetState({ dispatch }) {
      dispatch('auth/resetState', null, { root: true })
      dispatch('entries/resetState', null, { root: true })
      dispatch('lists/resetState', null, { root: true })
      dispatch('tags/resetState', null, { root: true })
      dispatch('repeaters/resetState', null, { root: true })
      dispatch('prompts/resetState', null, { root: true })
    },
  },

  modules: {
    app,
    auth,
    entries,
    messaging,
    lists,
    tags,
    repeaters,
    prompts,
    stripe,
  },

  plugins: [
    createPersistedState({
      paths: [
        'app.miniDrawer',
        'app.darkMode',
        'app.minimal',
        'app.welcomeAlert',
        'app.locale',
      ],
    }),
  ],
})

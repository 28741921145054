import Vue from 'vue'

Vue.directive('truncate', {
  inserted: function (el, binding) {
    truncateText(el, binding)
  },
  update: function (el, binding) {
    truncateText(el, binding)
  },
  componentUpdated: function (el, binding) {
    truncateText(el, binding)
  },
})

function truncateText(el, binding) {
  let text = binding.value ? binding.value : el.innerText
  if (text.length > 30) text = text.substring(0, 30) + '…'
  el.innerHTML = text
}

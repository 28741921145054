export default {
  en: {
    currency: { style: 'currency', currency: 'USD' },
    currencyNoDecimal: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    },
  },
  es: {
    currency: { style: 'currency', currency: 'USD' },
    currencyNoDecimal: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    },
  },
}

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
// import 'firebase/messaging'
import 'firebase/storage'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)

const auth = firebase.auth()
const db = firebase.firestore()
const functions = firebase.functions()
const firebaseAnalytics = firebase.analytics()
const storage = firebase.storage()

if (location.hostname === 'localhost' || location.host === 'localhost:5000') {
  auth.useEmulator('http://localhost:9099')
  db.useEmulator('localhost', 8090)
  functions.useEmulator('localhost', 5001)
  storage.useEmulator('localhost', 8091)
}

db.enablePersistence()

const increment = firebase.firestore.FieldValue.increment
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp

export {
  firebase,
  auth,
  db,
  functions,
  increment,
  serverTimestamp,
  firebaseAnalytics,
  storage,
}

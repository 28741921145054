import {
  db,
  firebaseAnalytics,
  functions,
  firebase,
  storage,
} from '../firebase'
import Vue from 'vue'

const REMOVE_ENTRY = 'REMOVE_ENTRY'
const SET_ENTRY_IMAGES = 'SET_ENTRY_IMAGES'
const CLEAR_STATE = 'CLEAR_STATE'
const SET_SHOW_ENTRY = 'SET_SHOW_ENTRY'

const defaultState = () => {
  return {
    entryDialogId: null,
    entryTypesMap: {
      task: {
        text: 'Task',
        icon: 'fas fa-check-square',
      },
      note: {
        text: 'Note',
        icon: 'fas fa-minus',
      },
      event: {
        text: 'Event',
        icon: 'fas fa-calendar-day',
      },
    },
    entryImages: {},
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  mutations: {
    [REMOVE_ENTRY]: (state, payload) => {
      const index = state.entries.findIndex(entry => entry.id === payload.id)
      state.entries.splice(index, 1)
    },
    [SET_ENTRY_IMAGES]: (state, { entryId, images }) =>
      Vue.set(state.entryImages, entryId, images),
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
    [SET_SHOW_ENTRY]: (state, id) => (state.entryDialogId = id),
  },

  actions: {
    async newEntry({ rootGetters, dispatch }, payload = {}) {
      if (payload.parentId && !rootGetters['auth/isPremium']) {
        dispatch('auth/openPremiumDialog', null, { root: true })

        return null
      }

      const uid = rootGetters['auth/uid']

      payload.title = payload.title || ''
      payload.type = payload.type || 'task'

      try {
        const batch = db.batch()

        const newEntryRef = db.collection(`users/${uid}/entries`).doc()

        batch.set(newEntryRef, {
          dateCreated: new Date(),
          dateUpdated: new Date(),
          dateComplete: null,
          dateScheduled: null,
          archived: false,
          list: null,
          parentId: null,
          tags: null,
          ...payload,
        })

        if (payload.parentId)
          batch.update(db.doc(`users/${uid}/entries/${payload.parentId}`), {
            dateUpdated: new Date(),
            subtasks: firebase.firestore.FieldValue.arrayUnion(newEntryRef.id),
          })

        await batch.commit()

        firebaseAnalytics.logEvent('create_entry')

        return newEntryRef
      } catch (error) {
        firebaseAnalytics.logEvent('exception', { description: error.message })
      }
    },

    async setReminder(_, payload) {
      const { entryId, dt } = payload
      const tsScheduled = dt.toMillis()
      const createReminderOnCall = functions.httpsCallable(
        'createReminderOnCall'
      )

      await createReminderOnCall({ entryId, tsScheduled })
    },

    async deleteReminder(_, entryId) {
      const deleteReminderOnCall = functions.httpsCallable(
        'deleteReminderOnCall'
      )

      await deleteReminderOnCall({ entryId })
    },

    async reorderEntriesSave(
      { rootGetters },
      { entries, context, updateData = {} }
    ) {
      const uid = rootGetters['auth/uid']

      const batch = db.batch()
      entries.forEach((e, i) => {
        const order = e.order || {}
        order[context] = i
        const data = {
          order,
          ...updateData,
        }
        batch.update(db.doc(`users/${uid}/entries/${e.id}`), data)
      })

      return batch.commit()
    },

    uploadImages({ rootGetters, dispatch }, { images, entryId }) {
      if (!rootGetters['auth/isPremium']) {
        dispatch('auth/openPremiumDialog', null, { root: true })

        return null
      }

      const uid = rootGetters['auth/uid']

      const imageUploads = images.map(image => {
        const imageDoc = db
          .collection(`users/${uid}/entries/${entryId}/images`)
          .doc()

        const name = `${imageDoc.id}-${image.file.name}`

        const path = `users/${uid}/entries/${entryId}/images/${name}`

        return storage
          .ref()
          .child(path)
          .put(image.file, {
            id: imageDoc.id,
            entryId,
            uid,
          })
          .then(taskSnap => {
            return taskSnap.ref.getDownloadURL()
          })
          .then(src => {
            const batch = db.batch()

            batch.set(imageDoc, {
              src,
              name,
              path,
              dateCreated: new Date(),
              dateUpdated: new Date(),
            })

            batch.update(db.doc(`users/${uid}/entries/${entryId}`), {
              numImages: firebase.firestore.FieldValue.increment(1),
            })

            return batch.commit()
          })
          .catch(e => {
            alert(e.message)
          })
      })

      return Promise.all(imageUploads)
    },

    async getImages({ rootGetters, state, commit }, { entryId, refresh }) {
      const existingImages = state.entryImages[entryId]

      if (!refresh && existingImages && existingImages.length) return

      const uid = rootGetters['auth/uid']

      const imageSnap = await db
        .collection(`users/${uid}/entries/${entryId}/images`)
        .limit(6)
        .get()

      const images = imageSnap.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })

      commit(SET_ENTRY_IMAGES, { entryId, images })
    },

    deleteImage({ rootGetters, dispatch }, { entryId, imageId }) {
      const uid = rootGetters['auth/uid']

      const batch = db.batch()

      batch.delete(db.doc(`users/${uid}/entries/${entryId}/images/${imageId}`))

      batch.update(db.doc(`users/${uid}/entries/${entryId}`), {
        numImages: firebase.firestore.FieldValue.increment(-1),
      })

      dispatch('getImages', { entryId, refresh: true })

      return batch.commit()
    },

    setComplete({ dispatch }, id) {
      dispatch('updateEntry', {
        id,
        data: {
          dateComplete: new Date(),
        },
      })
    },

    setIncomplete({ dispatch }, id) {
      dispatch('updateEntry', {
        id,
        data: {
          dateComplete: null,
        },
      })
    },

    async updateEntry({ rootGetters }, payload) {
      const uid = rootGetters['auth/uid']
      const { id, data } = payload

      try {
        const doc = await db.doc(`users/${uid}/entries/${id}`).update({
          dateUpdated: new Date(),
          ...data,
        })

        firebaseAnalytics.logEvent('update_entry')

        return doc
      } catch (error) {
        firebaseAnalytics.logEvent('exception', { description: error.message })
      }
    },

    resetState({ commit }) {
      commit(CLEAR_STATE)
    },
  },
}

import { auth, db, firebaseAnalytics } from '../firebase'
import router from '@/router.js'
import eventBus from '../eventBus'
import analytics from '../helpers/analytics'

const SET_AUTH_OBJECT = 'SET_AUTH_OBJECT'
const SET_USER = 'SET_USER'
const SET_CLAIMS = 'SET_CLAIMS'
const CLEAR_STATE = 'CLEAR_STATE'
const ADD_LISTENER = 'ADD_LISTENER'

const defaultState = () => {
  return {
    authObject: undefined,
    user: null,
    listeners: [],
    claims: null,
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    uid: state => state.authObject && state.authObject.uid,
    premium: state => state.user && state.user.premium,
    isPremium: (state, getters) =>
      (state.claims && state.claims.beta) ||
      (getters.premium && getters.premium.periodEnd.toDate() > new Date()),
    canRoute: ({ authObject, claims }) =>
      authObject === null || (authObject && !!claims),
  },

  mutations: {
    [SET_AUTH_OBJECT]: (state, user) => (state.authObject = user),
    [SET_USER]: (state, user) => (state.user = user),
    [SET_CLAIMS]: (state, claims) => (state.claims = claims),
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
    [ADD_LISTENER]: (state, listener) => state.listeners.push(listener),
  },

  actions: {
    async login(_, payload) {
      try {
        const email = payload.email.replace(/\s+/g, '')

        const { user } = await auth.signInWithEmailAndPassword(
          email,
          payload.password
        )

        firebaseAnalytics.logEvent('login')
        analytics.identify(user)

        if (user) {
          const redirectUrl = router.currentRoute.query.redirectUrl

          if (redirectUrl) {
            router.push(redirectUrl)
          } else {
            router.push({ name: 'dashboard' })
          }
        }

        return user
      } catch (error) {
        firebaseAnalytics.logEvent('exception', { description: error.message })
        alert(error.message)
      }
    },

    reauthenticate(_, payload) {
      const { email, password } = payload

      return auth.signInWithEmailAndPassword(email, password)
    },

    async register(context, payload) {
      try {
        const email = payload.email.replace(/\s+/g, '')

        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          payload.password
        )

        firebaseAnalytics.logEvent('sign_up')
        analytics.identify(user)

        if (user) {
          router.push({ name: 'dashboard' })
        }

        return user
      } catch (error) {
        firebaseAnalytics.logEvent('exception', { description: error.message })
        alert(error.message)
      }
    },

    async listenAuthState({ commit, dispatch }) {
      auth.onAuthStateChanged(async authObject => {
        commit(SET_AUTH_OBJECT, authObject)

        if (authObject) {
          dispatch('getClaims', { authObject })
          dispatch('listenUser', authObject)
          dispatch('lists/listenLists', authObject, { root: true })
          dispatch('messaging/setToken', authObject, { root: true })
          dispatch('tags/listenTags', authObject, { root: true })
          dispatch('repeaters/listenRepeaters', authObject, { root: true })
          dispatch('prompts/listenPrompts', authObject, { root: true })

          analytics.identify(authObject)
        }
      })
    },

    listenUser({ commit, dispatch }, authObject) {
      const uid = authObject.uid

      const usersListener = db
        .collection('users')
        .doc(uid)
        .onSnapshot(doc => {
          commit(SET_USER, doc.data())

          dispatch('refreshClaims', authObject)
          dispatch('redirectFromBeta')
        })

      commit(ADD_LISTENER, usersListener)
    },

    async logout({ dispatch }) {
      try {
        dispatch('messaging/deleteToken', null, { root: true })
        dispatch('resetState', null, { root: true })
        await auth.signOut()
        router.push({ name: 'home' })
      } catch (error) {
        alert(error.message)
      }
    },

    async deleteAccount({ dispatch, state }, payload) {
      try {
        await dispatch('reauthenticate', payload)

        await state.authObject.delete()

        dispatch('resetState', null, { root: true })

        router.push({ name: 'home' })
      } catch (error) {
        alert(error.message)
      }
    },

    async getClaims({ commit, dispatch }, { authObject, refresh = false }) {
      const { claims } = await authObject.getIdTokenResult(refresh)

      commit(SET_CLAIMS, claims)

      dispatch('refreshClaims', authObject)

      return claims
    },

    async refreshClaims({ state, dispatch }, authObject) {
      if (state.user === null || state.claims === null) return

      const needsRefreshing =
        state.user.claims &&
        Object.keys(state.user.claims).some(userClaimKey => {
          const userClaim = state.user.claims[userClaimKey]
          const stateClaim = state.claims[userClaimKey]

          return userClaim !== stateClaim
        })

      if (needsRefreshing) dispatch('getClaims', { authObject, refresh: true })
    },

    redirectFromBeta({ state }) {
      if (
        process.env.VUE_APP_BETA &&
        !(state.claims.beta || state.claims.admin)
      ) {
        window.location = process.env.VUE_APP_DOMAIN
      }
    },

    openPremiumDialog({ getters }) {
      if (!getters.isPremium) eventBus.$emit('openPremiumDialog')
    },

    resetState({ state, commit }) {
      state.listeners.forEach(listener => listener())
      commit(CLEAR_STATE)
    },
  },
}

import { db, functions } from '../firebase'
import { randomColor } from '../helpers/random-color'

const ADD_TAG = 'ADD_TAG'
const REPLACE_TAG = 'REPLACE_TAG'
const REMOVE_TAG = 'REMOVE_TAG'
const UPDATE_TAG = 'UPDATE_TAG'
const ADD_LISTENER = 'ADD_LISTENER'
const CLEAR_STATE = 'CLEAR_STATE'

const defaultState = () => {
  return {
    tags: [],
    listeners: [],
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    activeTags: state => state.tags.filter(l => l.deleting !== true),
  },

  mutations: {
    [ADD_TAG]: (state, payload) => state.tags.push(payload),
    [REPLACE_TAG]: (state, payload) => {
      const index = state.tags.findIndex(tag => tag.id === payload.id)
      state.tags.splice(index, 1, payload)
    },
    [REMOVE_TAG]: (state, payload) => {
      const index = state.tags.findIndex(tag => tag.id === payload.id)
      state.tags.splice(index, 1)
    },
    [ADD_LISTENER]: (state, listener) => state.listeners.push(listener),
    [UPDATE_TAG]: (state, payload) => {
      const index = state.tags.findIndex(tag => tag.id === payload.id)
      const updatedTag = Object.assign({}, state.tags[index], payload.data)
      state.tags.splice(index, 1, updatedTag)
    },
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
  },

  actions: {
    listenTags({ commit }, authObject) {
      const tagsListener = db
        .collection(`users/${authObject.uid}/tags`)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const doc = change.doc

            const payload = {
              id: doc.id,
              ...doc.data(),
            }

            if (change.type === 'added') commit(ADD_TAG, payload)
            else if (change.type === 'modified') commit(REPLACE_TAG, payload)
            else if (change.type === 'removed') commit(REMOVE_TAG, payload)
          })
        })

      commit(ADD_LISTENER, tagsListener)
    },

    createTag({ rootGetters, dispatch }, tag = '') {
      if (!rootGetters['auth/isPremium']) {
        dispatch('auth/openPremiumDialog', null, { root: true })

        return null
      }

      const uid = rootGetters['auth/uid']

      return db.doc(`users/${uid}/tags/${tag}`).set({
        dateCreated: new Date(),
        dateUpdated: new Date(),
        color: randomColor('lighten3'),
      })
    },

    updateTag({ rootGetters }, { tagId, data = {} }) {
      const uid = rootGetters['auth/uid']

      return db.doc(`users/${uid}/tags/${tagId}`).update({
        dateUpdated: new Date(),
        ...data,
      })
    },

    destroyTag({ commit }, tagId) {
      const destroyTagOnCall = functions.httpsCallable('destroyTagOnCall')

      commit(UPDATE_TAG, { id: tagId, data: { deleting: true } })

      return destroyTagOnCall({ tagId })
    },

    resetState({ state, commit }) {
      state.listeners.forEach(listener => listener())
      commit(CLEAR_STATE)
    },
  },
}

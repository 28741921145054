export default {
  identify(authObject) {
    window.analytics.identify(authObject.uid, {
      email: authObject.email,
    })
  },

  page(name) {
    window.analytics.page(name)
  },
}

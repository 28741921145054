<template>
  <v-app>
    <Navbar />

    <v-main>
      <router-view />
    </v-main>

    <EntryForm v-if="authObject" />

    <PremiumDialog v-if="authObject && !isPremium" />

    <v-snackbar v-model="newContentSnackbar">
      {{ $t('app.new-content-available') }}
      <v-btn @click="swSkipWaiting" color="white">{{
        $t('app.click-to-update')
      }}</v-btn>
    </v-snackbar>

    <v-snackbar v-model="newToastSnackbar">
      <div class="flex-grow-1 text-center">
        {{ toastMessage }}
      </div>
    </v-snackbar>

    <HelpDrawer v-if="authObject" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import eventBus from './eventBus'
import { firebaseAnalytics } from '@/firebase'

import Navbar from './components/Navbar'

export default {
  name: 'App',

  components: {
    Navbar,
    PremiumDialog: () => import('./components/PremiumDialog.vue'),
    EntryForm: () => import('./components/EntryForm.vue'),
    HelpDrawer: () => import('./components/HelpDrawer.vue'),
  },

  data() {
    return {
      newContentSnackbar: false,
      newToastSnackbar: false,
      toastMessage: '',
    }
  },

  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapGetters('auth', ['canRoute', 'isPremium']),
    ...mapState('auth', ['authObject']),
  },

  created() {
    this.listenEvents()
  },

  mounted() {
    this.checkSuccessfulCheckout()
  },

  methods: {
    ...mapActions('app', ['swSkipWaiting']),

    listenEvents() {
      eventBus.$on('toast', message => {
        this.toastMessage = message
        this.newToastSnackbar = true
      })
    },

    checkSuccessfulCheckout() {
      const url = new URL(window.location.href)

      const sessionId = url.searchParams.get('session_id')

      if (sessionId) {
        firebaseAnalytics.logEvent('checkout_success', {
          value: 3,
          sessionId,
          currency: 'usd',
        })
        eventBus.$emit('toast', `🎉 ${this.$t('app.premium-toast-message')}`)
      }
    },
  },
}
</script>

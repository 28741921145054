import { db, functions } from '../firebase'
import { randomColor } from '../helpers/random-color'

const ADD_LIST = 'ADD_LIST'
const REPLACE_LIST = 'REPLACE_LIST'
const REMOVE_LIST = 'REMOVE_LIST'
const UPDATE_LIST = 'UPDATE_LIST'
const ADD_LISTENER = 'ADD_LISTENER'
const CLEAR_STATE = 'CLEAR_STATE'

const defaultState = () => {
  return {
    lists: [],
    listeners: [],
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    activeLists: state => state.lists.filter(l => l.deleting !== true),
    listMap: (_, getters) => {
      const map = {}
      getters.activeLists.forEach(l => (map[l.id] = l))
      return map
    },
  },

  mutations: {
    [ADD_LIST]: (state, payload) => state.lists.push(payload),
    [REPLACE_LIST]: (state, payload) => {
      const index = state.lists.findIndex(list => list.id === payload.id)
      state.lists.splice(index, 1, payload)
    },
    [REMOVE_LIST]: (state, payload) => {
      const index = state.lists.findIndex(list => list.id === payload.id)
      state.lists.splice(index, 1)
    },
    [ADD_LISTENER]: (state, listener) => state.listeners.push(listener),
    [UPDATE_LIST]: (state, payload) => {
      const index = state.lists.findIndex(list => list.id === payload.id)
      const updatedList = Object.assign({}, state.lists[index], payload.data)
      state.lists.splice(index, 1, updatedList)
    },
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
  },

  actions: {
    listenLists({ commit }, authObject) {
      const usersListener = db
        .collection(`users/${authObject.uid}/lists`)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const doc = change.doc

            const payload = {
              id: doc.id,
              ...doc.data(),
            }

            if (change.type === 'added') commit(ADD_LIST, payload)
            else if (change.type === 'modified') commit(REPLACE_LIST, payload)
            else if (change.type === 'removed') commit(REMOVE_LIST, payload)
          })
        })

      commit(ADD_LISTENER, usersListener)
    },

    createList({ rootGetters }, title = '') {
      const uid = rootGetters['auth/uid']

      return db.collection(`users/${uid}/lists`).add({
        title,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        color: randomColor('accent2'),
      })
    },

    updateList({ rootGetters }, { id, data }) {
      const uid = rootGetters['auth/uid']

      return db.doc(`users/${uid}/lists/${id}`).update({
        ...data,
        dateUpdated: new Date(),
      })
    },

    destroyList({ commit }, listId) {
      const destroyListOnCall = functions.httpsCallable('destroyListOnCall')

      commit(UPDATE_LIST, { id: listId, data: { deleting: true } })

      return destroyListOnCall({ listId })
    },

    resetState({ state, commit }) {
      state.listeners.forEach(listener => listener())
      commit(CLEAR_STATE)
    },
  },
}

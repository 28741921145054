// import { messaging, db } from '../firebase'

export default {
  namespaced: true,

  actions: {
    async setToken({ rootState }) {
      // const token = messaging && (await messaging.getToken())
      // if (token)
      //   return db
      //     .doc(`users/${rootState.auth.authObject.uid}/fcmTokens/${token}`)
      //     .set({
      //       platform: 'desktop',
      //       token,
      //       dateCreated: new Date(),
      //     })
    },

    async deleteToken() {
      // const token = messaging && (await messaging.getToken())
      // if (token) return messaging.deleteToken(token)
    },
  },
}

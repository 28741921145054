import { DateTime } from 'luxon'
import ScopeMap from '../helpers/scope-map'

export default {
  install: Vue => {
    Vue.prototype.$DateTime = DateTime

    Object.defineProperty(Vue.prototype, '$scopeMap', {
      value: ScopeMap,
    })
  },
}

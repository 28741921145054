import { DateTime } from 'luxon'
import i18n from '../i18n'
import getBrowserLocale from '@/util/i18n/get-browser-locale'
import { supportedLocalesInclude } from '@/util/i18n/supported-locales'
import scopeMap from '../helpers/scope-map'

const SET_SELECTED_DATE = 'SET_SELECTED_DATE'
const SET_SCOPE = 'SET_SCOPE'
const SET_SIDEBAR = 'SET_SIDEBAR'
const SET_SW_REGISTRATION = 'SET_SW_REGISTRATION'
const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'
const SET_DARK_MODE = 'SET_DARK_MODE'
const SET_MINIMAL = 'SET_MINIMAL'
const TOGGLE_MINI_DRAWER = 'TOGGLE_MINI_DRAWER'
const SET_HELP_DRAWER = 'SET_HELP_DRAWER'
const SET_WELCOME_ALERT = 'SET_WELCOME_ALERT'
const SET_LOCALE = 'SET_LOCALE'

const defaultState = () => {
  return {
    selectedDate: null,
    scope: null,
    sidebarContents: scopeMap.week,
    swRegistration: null,
    mobileMaxWidth: 767,
    windowWidth: 0,
    miniDrawer: false,
    darkMode: false,
    minimal: false,
    helpDrawer: false,
    welcomeAlert: true,
    locale: null,
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    newContentAvailable: state => !!state.swRegistration,
    isMobile: state => state.windowWidth <= state.mobileMaxWidth,
    selectedDateIsToday: state => {
      return (
        state.selectedDate.toLocaleString() ===
        DateTime.local().toLocaleString() && state.scope === scopeMap.day // eslint-disable-line
      )
    },
  },

  mutations: {
    [SET_SELECTED_DATE]: (state, date) => (state.selectedDate = date),
    [SET_SCOPE]: (state, date) => (state.scope = date),
    [SET_SIDEBAR]: (state, option) => (state.sidebarContents = option),
    [SET_SW_REGISTRATION]: (state, reg) => (state.swRegistration = reg),
    [SET_WINDOW_WIDTH]: (state, width) => (state.windowWidth = width),
    [SET_DARK_MODE]: (state, value) => (state.darkMode = value),
    [SET_MINIMAL]: (state, value) => (state.minimal = value),
    [TOGGLE_MINI_DRAWER]: state => (state.miniDrawer = !state.miniDrawer),
    [SET_HELP_DRAWER]: (state, value) => (state.helpDrawer = value),
    [SET_WELCOME_ALERT]: (state, value) => (state.welcomeAlert = value),
    [SET_LOCALE]: (state, value) => (state.locale = value),
  },

  actions: {
    setDateAndScope({ commit }, options) {
      const date = (options && options.date) || DateTime.local()
      const scope = (options && options.scope) || scopeMap.day

      commit(SET_SELECTED_DATE, date)
      commit(SET_SCOPE, scope)

      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      if (width < 768) commit(SET_SIDEBAR, scope)
    },

    onResize({ commit }) {
      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      commit(SET_WINDOW_WIDTH, width)
      window.addEventListener('resize', () => {
        let width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        commit(SET_WINDOW_WIDTH, width)
      })
    },

    setDarkMode({ state, commit }, { vue, value }) {
      if (value === undefined) {
        vue.$vuetify.theme.dark = state.darkMode
      } else {
        vue.$vuetify.theme.dark = value
        commit(SET_DARK_MODE, value)
      }
    },

    swSkipWaiting({ state }) {
      state.swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },

    setStartingLocale({ dispatch, state }) {
      const browserLocale = getBrowserLocale()
      const twoDigitBrowserLocale = getBrowserLocale({ countryCodeOnly: true })

      if (state.locale) {
        dispatch('setLocale', state.locale)
      } else if (supportedLocalesInclude(browserLocale)) {
        dispatch('setLocale', browserLocale)
      } else if (supportedLocalesInclude(twoDigitBrowserLocale)) {
        dispatch('setLocale', twoDigitBrowserLocale)
      }
    },

    setLocale({ commit }, locale) {
      i18n.locale = locale
      commit(SET_LOCALE, locale)

      return locale
    },
  },
}

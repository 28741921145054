import { functions, firebaseAnalytics } from '../firebase'

export default {
  namespaced: true,

  actions: {
    async createCheckout(context, planId) {
      const createCheckoutOnCall = functions.httpsCallable(
        'createCheckoutOnCall'
      )

      firebaseAnalytics.logEvent('begin_checkout')

      const { data } = await createCheckoutOnCall({
        planId,
        redirectURL: window.location.href,
      })

      return data
    },

    cancelSubscription(context, subscriptionId) {
      const cancelSubscriptionOnCall = functions.httpsCallable(
        'cancelSubscriptionOnCall'
      )

      return cancelSubscriptionOnCall({ subscriptionId })
    },
  },
}

import { db } from '../firebase'
import { RRule } from 'rrule'

const ADD_REPEATER = 'ADD_REPEATER'
const REPLACE_REPEATER = 'REPLACE_REPEATER'
const REMOVER_REPEATER = 'REMOVER_REPEATER'
const ADD_LISTENER = 'ADD_LISTENER'
const CLEAR_STATE = 'CLEAR_STATE'

const defaultState = () => {
  return {
    repeaters: [],
    listeners: [],
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  mutations: {
    [ADD_REPEATER]: (state, payload) => state.repeaters.push(payload),
    [REPLACE_REPEATER]: (state, payload) => {
      const index = state.repeaters.findIndex(
        repeater => repeater.id === payload.id
      )
      state.repeaters.splice(index, 1, payload)
    },
    [REMOVER_REPEATER]: (state, payload) => {
      const index = state.repeaters.findIndex(
        repeater => repeater.id === payload.id
      )
      state.repeaters.splice(index, 1)
    },
    [ADD_LISTENER]: (state, listener) => state.listeners.push(listener),
    [CLEAR_STATE]: state => Object.assign(state, defaultState()),
  },

  actions: {
    listenRepeaters({ commit }, authObject) {
      const repeatersListener = db
        .collection(`users/${authObject.uid}/repeaters`)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const doc = change.doc
            const data = doc.data()
            const payload = {
              id: doc.id,
              ...data,
            }

            if (change.type === 'added') commit(ADD_REPEATER, payload)
            else if (change.type === 'modified')
              commit(REPLACE_REPEATER, payload)
            else if (change.type === 'removed')
              commit(REMOVER_REPEATER, payload)
          })
        })

      commit(ADD_LISTENER, repeatersListener)
    },

    createRepeater({ rootGetters, dispatch }, { taskId, rrule }) {
      if (!rootGetters['auth/isPremium']) {
        dispatch('auth/openPremiumDialog', null, { root: true })

        return null
      }

      const uid = rootGetters['auth/uid']

      const batch = db.batch()

      const repeaterDoc = db.collection(`users/${uid}/repeaters`).doc()

      const rruleString = rrule.toString()
      const text = RRule.fromString(rruleString).toText() // fix text issue

      batch.set(repeaterDoc, {
        dateCreated: new Date(),
        dateUpdated: new Date(),
        rrule: rruleString,
        text,
      })

      batch.update(db.doc(`users/${uid}/entries/${taskId}`), {
        repeaterId: repeaterDoc.id,
      })

      return batch.commit()
    },

    resetState({ state, commit }) {
      state.listeners.forEach(listener => listener())
      commit(CLEAR_STATE)
    },
  },
}
